import React from 'react'
import instagram from '../assets/instagram.svg';
import telegram from '../assets/telegram.svg';

const SocialNetworks = props => (
  <div className="flex items-center" {...props}>
    <a href="https://www.instagram.com/realt.bel/" target="_blank">
      <img alt="Instagram" className="w-30px h-30px m-2" src={instagram} />
    </a>
    <a href="https://telegram.me/agent007by" target="_blank">
      <img alt="Telegram" className="w-30px h-30px m-2" src={telegram} />
    </a>
  </div>
);

export default SocialNetworks;

import React from 'react';
import headerSvg from '../assets/header.svg';
import headerTabletSvg from '../assets/headerTablet.svg';
import headerXLSvg from '../assets/headerXL.svg';
import mergeClassNames from '../helpers/mergeClassNames';

const HeaderPolygon = ({className = '', ...rest}) => (
  <div
    className={mergeClassNames('flex items-end md:items-center justify-center overflow-hidden h-1050px xs:h-1100px md:h-800px lg:h-1260px', className)}
  >
    <picture>
      <source media="(min-width: 0px) and (max-width: 767px)" srcSet={headerSvg} />
      <source media="(min-width: 768px) and (max-width: 1023px)" srcSet={headerTabletSvg} />
      <source media="(min-width: 1024px) and (max-width: 1920px)" srcSet={headerSvg} />
      <source media="(min-width: 1921px)" srcSet={headerXLSvg} />
      <img src={headerSvg} alt="" className='max-w-none' {...rest} />
    </picture>
  </div>
)

export default HeaderPolygon;

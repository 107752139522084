import React from 'react';

const withImageGradient = Component => ({ leftShadow = false, ...rest }) => {
  const inset = leftShadow 
    ? '-top-8px -left-8px bottom-8px right-8px lg:-top-20px lg:-left-20px lg:bottom-20px lg:right-20px'
    : '-top-8px left-8px bottom-8px -right-px lg:-top-20px lg:left-20px lg:bottom-20px lg:-right-20px';
  return (
    <div className="relative">
      <Component {...rest} />
      <div
        className="primary-bg-gradient absolute rounded-20px inset-0"
      />
      <div
        className={`-z-10 primary-bg-gradient absolute rounded-20px ${inset}`}
      />
    </div>
  );
};

export default withImageGradient;
import React from 'react';
import HeaderPolygon from './headerPolygon';
import Topper from './topper';
import Hero from './hero';
import MyServices from './myServices';


const Header = () => (
  <header className="relative px-17px pt-15px lg:pt-60px">
    <HeaderPolygon className="absolute inset-0 -z-20" />
    <Topper />
    <Hero />
    <MyServices />
  </header>
);

export default Header;

import React from 'react'
import Menu from './menu';
import RequestCallLink from './requestCallLink';
import Phone from './phone';
import LogoFirstLastName from './logoFirstLastName';

const Topper = () => (
  <div className="container flex h-40px justify-between items-center">
    <LogoFirstLastName />
    <Menu />
    <div className="hidden lg:flex">
      <Phone className="font-semibold"/>
    </div>
    <div className="hidden lg:flex">
      <RequestCallLink />
    </div>
  </div>
);

export default Topper;

import React from 'react'
import mergeClassNames from '../helpers/mergeClassNames';

const ButtonWithGradient = ({ className, ...rest }) => (
  <button
    className={mergeClassNames("primary-bg-gradient-100 p-3 rounded-30px text-white text-18px py-16px", className)}
    {...rest}
  />
);

export default ButtonWithGradient;
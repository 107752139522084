import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// import logo from '../images/logo.png';

const Logo = props => (
  <StaticImage
    src="../images/logo.png"
    quality={95}
    formats={["auto", "webp", "avif"]}
    className="rounded-20px"
    alt="Лого"
    width={30}
    height={30}
  />
)
const FirstLastName = props => (
  <div {...props}>
    <div className="leading-20px font-semibold text-blacktext uppercase">Светлана Беляева</div>
    <div className="leading-20px font-normal">Агент по недвижимости</div>
  </div>
)

const LogoFirstLastName = () => (
  <div className="flex items-center">
    <Logo />
    <FirstLastName className="ml-2" />
  </div>
);

export default LogoFirstLastName;

import React from 'react'
import mail from '../assets/mail.svg';

const Email = props => (
  <div className="flex items-center">
    <img
      alt=""
      className="w-16px h-16px mr-15px"
      src={mail}
      {...props}
    />
    <a href="mailto:3032233@gmail.com">303 22 33@gmail.com</a>
  </div>
);

export default Email;

// https://stackoverflow.com/a/1584377
const arrayUnique = array => {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }
  return a;
}

const mergeClassNames = (classes, otherClasses = '') => (arrayUnique([
  ...classes.split(' '),
  ...otherClasses.split(' '),
]).join(' '));

export default mergeClassNames;
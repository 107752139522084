import React from 'react'
import phone from '../assets/telephone.svg';
import mergeClassNames from '../helpers/mergeClassNames'

const Phone = ({ linkClassName, ...rest}) => (
  <a className={mergeClassNames("flex items-center", linkClassName)} href="tel:+375293032236">
    <img alt="" src={phone} className="w-18px h-18px" />
    <span {...rest}>+ 375 29 303 22 36</span>
  </a>
);

export default Phone;

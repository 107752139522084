import React, { useCallback, useState } from 'react';
import { Formik, Form, Field, useFormikContext } from 'formik';
import axios from 'axios';
import ButtonWithGradient from './buttonWithGradient';
import mergeClassNames from '../helpers/mergeClassNames';

const TELEGRAM_BOT_TOKEN = '2140191208:AAHxFhs-2jErS4YcKSESEvxwmGi_vNdK9TM';
const TELEGRAM_BOT_GROUP_ID = '-621722970';

const submitForm = values => axios.post(
  `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`,
  // 'https://beliayeu.free.beeceptor.com',
  {
    chat_id: TELEGRAM_BOT_GROUP_ID,
    text: `Имя: ${values.name}. Телефон: ${values.phone}.`
  },
)

const FieldError = props => (
  <div
    className="block w-full px-10px"
  >
    <div
      className="text-red font-bold"
      {...props}
    />
  </div>
)

const FieldInput = ({ fieldName, label }) => {
  const { values, touched, errors, handleBlur } = useFormikContext();
  const [focused, setFocused] = useState(false);
  const labelClasses = focused || values[fieldName] ? 'origin-top-left transform -translate-y-4 scale-75 transition-transform duration-500' : '';
  return (
    <div className="block relative mb-30px">
      <span
        className="relative inline-block w-full align-top box-border"
      >
        <Field
          id={fieldName}
          name={fieldName}
          className="relative z-20 h-36px outline-none w-full bg-transparent"
          onBlur={useCallback(e => { handleBlur(e); setFocused(false); }, [handleBlur])}
          onFocus={useCallback(() => setFocused(true), [])}
          autoComplete="off"
        />
        <span className="z-10 border-b absolute inset-0" />
      </span>
      <label
        className={mergeClassNames("absolute w-full inline-block left-0 top-2px z-30", labelClasses)}
        htmlFor={fieldName}
      >
        {label}
      </label>
      {errors[fieldName] && touched[fieldName] && <FieldError>{errors[fieldName]}</FieldError>}
    </div>
  )
}

const RequestCallForm = ({ onFormSubmit }) => {
  return (
    <div className="w-300px md:w-400px">
      <h2 className="md:w-3/4 mx-auto mb-40px text-30px lg:text-37px">Заказать звонок</h2>
      <Formik
        initialValues={{ name: '', phone: '' }}
        validate={values => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Пожалуйста, укажите Ваше имя';
          }
          if (!values.phone) {
            errors.phone = 'Пожалуйста, укажите телефон';
          } else if (
            !/(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(values.phone)
          ) {
            errors.phone = 'Пожалуйста, укажите корректный телефон';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          const result = await submitForm(values);
          onFormSubmit({
            result,
            values,
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="md:w-3/4 mx-auto">
            <FieldInput
              fieldName="name"
              label="Имя"
              aria-invalid={false}
            />
            <FieldInput
              fieldName="phone"
              label="Номер мобильного телефона"
              type="tel"
              aria-invalid={false}
            />
            {
              <ButtonWithGradient type="submit" disabled={isSubmitting}>
                Отправить
              </ButtonWithGradient>
            }
          </Form>
        )}
      </Formik>
    </div>
  )
}


export default RequestCallForm;
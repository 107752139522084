import React from 'react';
import TextOverlay from './textOverlay';
import houseSVG from '../assets/house.svg';
import purchaseSVG from '../assets/purchase.svg';
import realEstateSVG from '../assets/real-estate.svg';
import taxiSVG from '../assets/taxi.svg';
import serviceBgSVG from '../assets/serviceRectangle.svg';
import mergeClassNames from '../helpers/mergeClassNames';

const Service = ({ wrapperClassname, iconSrc, children }) => (
  <div 
    className={mergeClassNames("bg-service service-item p-10 -m-2 sm:m-2 w-320px h-470px rounded-20px static lg:absolute flex flex-col items-center", wrapperClassname)}
    style={{
      backgroundImage: `url(${serviceBgSVG})`
    }}
  >
    <div className="relative mt-60px">
      <div
        className="absolute rounded-20px w-100px h-140px"
        style={{
          background: 'rgba(245, 235, 254, 0.5)',
          transform: 'matrix(0.9, -0.44, -0.44, -0.9, 0, 0)',
        }}/>
      <img
        alt=""
        src={iconSrc}
        className="w-100px h-100px relative z-5"
      />
    </div>
    {children}
  </div>
);

const ServiceTitle = ({ children, ...rest }) => (
  <h3 {...rest} className="font-medium text-blacktext text-center leading-20px mt-20 mb-5">{children}</h3>
);

const ServiceDescriptionList = props => (
  <ul className="font-normal list-disc" {...props} />
);

const MyServices = () => (
  <div className="static lg:absolute mt-80px top-500px lg:max-w-1000px xl:max-w-auto">
    <TextOverlay name="services" text="Мои услуги" wrapperClassName="-z-30 -ml-34px"/>
    <div className="container flex flex-wrap align-center justify-center">
      <Service iconSrc={houseSVG}>
        <ServiceTitle>Продажа недвижимости</ServiceTitle>
        <ServiceDescriptionList>
          <li>первичное жилье</li>
          <li>вторичное жилье</li>
          <li>дом/дача/коттедж</li>
          <li>срочная продажа</li>
        </ServiceDescriptionList>
      </Service>
      <Service iconSrc={purchaseSVG}>
        <ServiceTitle>Покупка недвижимости</ServiceTitle>
        <ServiceDescriptionList>
          <li>первичное жилье</li>
          <li>вторичное жилье</li>
          <li>дом/дача/коттедж</li>
        </ServiceDescriptionList>
      </Service>
      <Service iconSrc={realEstateSVG}>
        <ServiceTitle>Обмен недвижимости</ServiceTitle>
        <ServiceDescriptionList>
          <li>вторичное жилье</li>
          <li>дом/дача/коттедж</li>
        </ServiceDescriptionList>
      </Service>
      <Service iconSrc={taxiSVG}>
        <ServiceTitle>Разъезд по вопросам недвижимости</ServiceTitle>
      </Service>
    </div>
  </div>
)

export default MyServices;
import React from 'react'
import Helmet from 'react-helmet'
import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet
        bodyAttributes={{
          class: 'font-montserrat'
        }}
      />
      <Header />
      <main>{children}</main>
      <Footer/>
    </div>
  )
}

export default Layout

import React from 'react'
import mergeClassNames from '../helpers/mergeClassNames'

const LinkWithGradient = ({ className, children, ...rest}) => (
  <a
    className={mergeClassNames("underline font-medium text-gradient primary-bg-gradient-100 cursor-pointer hover:primary text-20px", className)}
    {...rest}
  >
    {children}
  </a>
);

export default LinkWithGradient;
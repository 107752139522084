import React, { useState, useEffect } from 'react'
import { slide as BurgerMenu } from 'react-burger-menu'
import Phone from './phone';
import RequestCallLink from './requestCallLink';
import '../styles/burgerMenu.css'

const MenuItem = ({ itemName, onClick, children }) => {
  /*
   * Store our anchorTarget in state
   * We do not set it here, preferring to wait for after the component
   * is mounted to avoid any errors
   */
  const [anchorTarget, setAnchorTarget] = useState(null);

  /*
   * When the component mounts and/or updates, set our AnchorTarget based
   * on the itemName
   */
  useEffect(() => {
    setAnchorTarget(document.getElementsByName(itemName)[0]);
  }, [itemName]);

  /*
   * Where all the magic happens -- scrollIntoView on click
   */
  const handleClick = event => {
    event.preventDefault();
    if (onClick) {
      onClick(event);
    }
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  /*
   * Return the MenuItem as JSX
   * Remember to set your ariaLabel for accessability!
   */
  return (
    <li className="mb-3 lg:mt-0">
      <a
        className="m-0 lg:m-3 font-normal hover:text-gradient hover:underline hover:primary-bg-gradient-100"
        href={`#${itemName}`}
        onClick={handleClick}
        aria-label={`Перейти к ${children}`}
      >
        {children}
      </a>
    </li>
  );
};

const DesktopMenu = props => (
  <nav className="hidden lg:block" aria-label="Главное меню">
    <ul className="lg:flex">
      <MenuItem itemName="services">Услуги</MenuItem>
      <MenuItem itemName="about-me">Обо мне</MenuItem>
      <MenuItem itemName="reviews">Отзывы</MenuItem>
      <MenuItem itemName="advantages">Преимущества</MenuItem>
    </ul>
  </nav>
);

const MobileMenu = props => {
  const [isOpen, setOpen] = useState(false)
  const handleIsOpen = () => setOpen(!isOpen);
  const closeSideBar = () => setOpen(false);

  return (
    <BurgerMenu
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={closeSideBar}
      className="block lg:hidden secondary-bg-gradient top-0"
      burgerButtonClassName="block lg:hidden"
      overlayClassName="left-0 top-0"
      menuClassName="p-4 text-16px"
      right
    >
      <ul className="outline-none focus:outline-none">
        <MenuItem onClick={closeSideBar} itemName="services">Услуги</MenuItem>
        <MenuItem onClick={closeSideBar} itemName="about-me">Обо мне</MenuItem>
        <MenuItem onClick={closeSideBar} itemName="reviews">Отзывы</MenuItem>
        <MenuItem onClick={closeSideBar} itemName="advantages">Преимущества</MenuItem>
      </ul>
      <Phone onClick={closeSideBar} linkClassName="mb-3 lg:mt-0" />
      <RequestCallLink onClick={closeSideBar} className="mb-3 lg:mt-0" />
    </BurgerMenu>
  )
}

const Menu = props => (
  <>
    <DesktopMenu />
    <MobileMenu />
  </>
)

export default Menu;
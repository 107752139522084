import React, { useState } from 'react';
import '../styles/modal.css';
import { Modal } from 'react-responsive-modal';
import RequestCallForm from '../components/requestCallForm';

const ThankYou = () => (
  <div className="w-3/4 mx-auto mb-40px text-20px">Спасибо. Ваша заявка принята. Светлана свяжется с Вами.</div>
);

const Error = () => (
  <div className="w-3/4 mx-auto mb-40px text-20px">
    Произошла ошибка при оформлении заявки. Попробуйте, пожалуйста, позже или свяжитесь со Светланой по телефону&nbsp; 
    <a className="whitespace-nowrap	font-semibold" href="tel:+375293032236">+ 375 29 303 22 36</a>
  </div>
);

const FormStatus = {
  NotSubmitted: 0,
  Success: 1,
  Error: 2,
}

const withRequestCallModal = Component => ({ onClick, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [ formStatus, setFormStatus ] = useState(FormStatus.NotSubmitted);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <Component {...rest} onClick={e => {
        if (onClick) onClick(e);
        onOpenModal();
      }} />
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          modal: 'primary-bg-gradient-100',
        }}
      >
        { 
          formStatus === FormStatus.NotSubmitted &&
          <RequestCallForm onFormSubmit={({ result: { status }}) => {
            if (status === 200) {
              setFormStatus(FormStatus.Success);
            } else {
              setFormStatus(FormStatus.Error);
            }
          }} />
        }
        { 
          formStatus === FormStatus.Success &&
          <ThankYou />
        }
        { 
          formStatus === FormStatus.Error &&
          <Error />
        }
      </Modal>
    </>
  );
};

export default withRequestCallModal;
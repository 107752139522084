import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import verticalDotsSVG from '../assets/verticalDots.svg';
import withImageGradient from '../hocs/withImageGradient';
import GetConsultation from './getConsulation';

const SolveProblemsQuickly = props => (
  <div {...props}>
    <div className="leading-37px lg:leading-55px text-30px lg:text-45px">Решаю задачи <span className="text-danger whitespace-nowrap">с недвижимостью</span></div>
    <div className="font-semibold leading-37px lg:leading-55px text-30px lg:text-45px">быстро, четко, <span className="whitespace-nowrap">без нервов</span></div>
    <div className="mt-6 text-16px leading-20px mr-0px md:mr-10px">Со мной Вы можете быть спокойны за результат</div> 
  </div>
)

const HeroText = props => (
  <div {...props}>
    <SolveProblemsQuickly className="mb-56px h-215px"/>
    <GetConsultation className="px-28px">Получить консультацию</GetConsultation>
  </div>
);

const HeroImage = withImageGradient(() => (
  <StaticImage
    src="../images/hero.jpg"
    quality={95}
    formats={["auto", "webp", "avif"]}
    className="rounded-20px max-w-355px md:max-w-none"
    imgClassName="rounded-20px"
    width={660}
    height={990}
    alt="Светлана Беляева"
  />
));

const Hero = props => (
  <div {...props} className="container mt-80px lg:235px flex flex-wrap md:flex-nowrap">
    <HeroText className="mb-60px lg:mt-40px xl:mt-110px" />
    <div className="lg:mt-0 lg:ml-auto relative">
      <HeroImage leftShadow={true} />
      <img
        className="w-60px h-220px absolute top-200px -left-40px"
        src={verticalDotsSVG}
        alt=""
      />
    </div>
  </div>
);

export default Hero;

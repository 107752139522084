import React from 'react';
import mergeClassNames from '../helpers/mergeClassNames';

const TextOverlay = ({ name, text, wrapperClassName }) => (
  <div name={name} className={mergeClassNames("relative", wrapperClassName)}>
    <div
      className="overflow-x-hidden whitespace-nowrap text-100px lg:text-160px leading-120px lg:leading-195px uppercase text-white text-stroke-gradient primary-bg-gradient-15"
    >
      {text}
    </div>
    <span className="absolute text-18px font-semibold top-50px lg:top-90px left-40px lg:left-200px">{text}</span>
  </div>
);

export default TextOverlay;
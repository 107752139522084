import React from 'react'
import LogoFirstLastName from './logoFirstLastName';
import Phone from './phone';
import Email from './email';
import SocialNetworks from './socialNetworks';

const Footer = () => (
  <footer className="primary-bg-gradient pt-17px">
    <div className="container flex h-235px justify-between items-center flex-col lg:flex-row">
      <LogoFirstLastName />
      <Phone />
      <Email />
      <SocialNetworks />
    </div>
  </footer> 
);

export default Footer;
